import React, { useEffect, useState } from 'react';
import { Toaster } from 'react-hot-toast';
import { Route, Routes } from 'react-router-dom';
import NotFound from '../Pages/404';
import PrivateRoute from './privateRoute';
import PublicRoute from './PublicRoute';
import GoogleSignIn from '../Pages/Account/googleSignIn';
import { getUserApi } from '../apis/index.js';
import Registration from '../Pages/RegistrationPage/Registration.js';
import DashboardOdds from '../Pages/DashboardOdds/Index.js';
import NotificationOdds from '../Pages/NotificationOdds/NotificationOdds.js';
import Index from '../Pages/Consultation/Index.js';
import RequestBroadcast from '../Pages/RequestBroadcast/Index.js';
import WalletIndex from '../Pages/Wallet/WalletIndex.js';
import ProfileLayout from '../Pages/ProfileLayout/ProfileLayout.js';
import PersonalDetails from '../Pages/ProfileLayout/PersonalDetails.js';
import AboutUs from '../Pages/ProfileLayout/AboutUs.js'
import AppointmentHistory from '../Pages/ProfileLayout/AppoinmentHistory.js';
import DeleteAccount from '../Pages/ProfileLayout/DeleteAccount.js';
import Logout from '../Pages/ProfileLayout/Logout.js';
import ChangePassword from '../Pages/ProfileLayout/ChangePassword.js';
import CalendarIndex from '../Pages/Calendar/CalendarIndex.js';
import History from '../Pages/AppointmentHistory/History.js';
import HelpCenter from '../Pages/ProfileLayout/Helpcenter.js';
export default function MainRouter() {
  const [loggedInUser, setLoggedInUser] = useState();

  const getLoggedInUser = async () => {
    try {
      const res = await getUserApi();
      if (res.status === 200) {
        setLoggedInUser(res?.data?.user[0]);
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    getLoggedInUser();
  }, []);

  return (
    <>
      <Routes>
        <Route
          path="/sign-up"
          element={
            <PublicRoute>
              <GoogleSignIn
                formType={'SIGNUP'}
                formGreetings={'Join doctor portal !'}
                formSubGreetings={'Enter your email and password to sign up'}
              />
            </PublicRoute>
          }
        />
        <Route
          path="/sign-in"
          element={
            <PublicRoute>
              <GoogleSignIn
                formGreetings={'Welcome Back !'}
                formSubGreetings={'Enter your email and password to sign in'}
              />
            </PublicRoute>
          }
        />

        <Route
          path="/registration"
          element={
            <PublicRoute>
              <Registration />
            </PublicRoute>
          }
        />
        <Route
          path="/"
          element={
            <PrivateRoute>
              <DashboardOdds />
            </PrivateRoute>
          }
        />
        <Route
          path="/calendar"
          element={
            <PrivateRoute>
              <CalendarIndex />
            </PrivateRoute>
          }
        />
        <Route
          path="/notification"
          element={
            <PrivateRoute>
              <NotificationOdds />
            </PrivateRoute>
          }
        />
        <Route
          path="/consultation"
          element={
            <PrivateRoute>
              <Index />
            </PrivateRoute>
          }
        />
        <Route
          path="/requestbroadcast"
          element={
            <PrivateRoute>
              <RequestBroadcast />
            </PrivateRoute>
          }
        />
        <Route
          path="/wallet"
          element={
            <PrivateRoute>
              <WalletIndex />
            </PrivateRoute>
          }
        />
        <Route
          path="/history"
          element={
            <PrivateRoute>
              <History />
            </PrivateRoute>
          }
        />
        <Route
          path="/profile"
          element={
            <PrivateRoute>
              <ProfileLayout />
            </PrivateRoute>
          }
        >
          <Route
            path="/profile/appointment"
            element={
              <PrivateRoute>
                <AppointmentHistory />
              </PrivateRoute>
            }
          />
          <Route
            path="/profile/help"
            element={
              <PrivateRoute>
                <HelpCenter />
              </PrivateRoute>
            }
          />
          <Route
            path="/profile/details"
            element={
              <PrivateRoute>
                <PersonalDetails />
              </PrivateRoute>
            }
          />
          <Route
            path="/profile/changepassword"
            element={
              <PrivateRoute>
                <ChangePassword />
              </PrivateRoute>
            }
          />

          <Route
            path="/profile/deleteaccount"
            element={
              <PrivateRoute>
                <DeleteAccount />
              </PrivateRoute>
            }
          />
          <Route
            path="/profile/aboutus"
            element={
              <PrivateRoute>
                <AboutUs />
              </PrivateRoute>
            }
          />
          <Route
            path="/profile/logout"
            element={
              <PrivateRoute>
                <Logout />
              </PrivateRoute>
            }
          />
          </Route>

        <Route
          path="*"
          element={
            <PrivateRoute>
              <NotFound />
            </PrivateRoute>
          }
        />
      </Routes>
      <Toaster
        position="top-center"
        reverseOrder={false}
        containerStyle={{ zIndex: '99999' }}
      />
    </>
  );
}
