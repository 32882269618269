import { API_BASE_URL } from './baseurl';
export const getToken = () => localStorage.getItem('token');
export const setToken = (token) => localStorage.setItem('token', token);
export const removeToken = () => localStorage.removeItem('token');
export const isAuth = () => Boolean(getToken());
export const logoutOnJwtExpire = () => localStorage.clear();
export const preventBack = () => window.history.forward();
export const assignBucketUrl = (path) => {
  return encodeURI(path?.startsWith('uploads/') ? API_BASE_URL + '/' + path : path);
};
export const style = {
  control: (base) => ({
    ...base,
    border: 0,
    boxShadow: 'none',
    backgroundColor: 'transparent',
    margin: '0 !important',
    minHeight: '0'
  }),
};

export const autoHeightText = (el) => {
  el.target.style.height = '69px';
  el.target.style.height = 1 + el.target.scrollHeight + 'px';
};
const date1 = new Date('April 10, 2023 17:24:00');

export const capitalizeFirstLetter = (string) => {
  if (string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  } else {
    return '';
  }
};