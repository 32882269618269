import {
  GETINSTAGRAM,
  fileUpload,
  GET,
  PATCH,
  POST,
  NOTOKENPOST,
  fileUploadwithprogress,
  DeleteApi,
  DELETE,
  GETSpecialization,
} from './api-interface';

// Login / User APIs
export const loginApi = (payload) =>
  NOTOKENPOST('/api/v1/common/auth/sendOtp', payload);
export const verifyOtp = (payload) =>
  NOTOKENPOST('/api/v1/common/auth/verifyOtp', payload);
export const verifyToken = (payload) =>
  NOTOKENPOST('/api/v1/doctor/user/googlelogin', payload);
export const getUserApi = () => GET('/api/v1/doctor/user/byAuth');
export const FreshdeskApi = (payload) =>
  POST('/api/v1/common/Freshdesk/CreateContacts', payload);
export const getDoctorApibyUserID = (id) =>
  GET(`/api/v1/doctor/profile/getprofile?userId=${id}`);
export const updateUserApi = (payload) => PATCH('/api/v1/user/update', payload);
export const updateUserByIdApi = (payload) =>
  PATCH('/api/v1/user/updateById', payload);

export const fetchRegionApi = () => GET('/json/region/region.json');
export const fetchClientStatusApi = () => GET('/json/clientStatus/status.json');
export const fetchIndustryApi = () => GET('/api/v1/helper/industry-list');
export const fetchCountrycodeApi = () =>
  GET('/json/countryCode/countryCode.json');
export const fetchEngagementTypeApi = () =>
  GET('/json/engagementType/engagementType.json');
export const fetchCategoryApi = () => GET('/json/categories/categories.json');
export const fetchClientApi = () => GET('/json/clientType/clientType.json');
export const fetchClientApiV2 = () =>
  GET('/api/v2/admin/companies/allCompanies');
export const uploadProfileImgApi = (payload) =>
  fileUpload('/api/v1/common/uploads/file', payload);
//fikle upload API for brief...

//fikle upload API for projects...
export const ProjectfileUploadApi = (payload, onUploadProgress, file) => {
  return fileUploadwithprogress(
    '/api/v1/common/uploads/projectattach',
    payload,
    onUploadProgress,
    file
  );
};
export const createBriefAttachApi = (payload) =>
  POST('/api/v2/admin/brief/attachment/add', payload);
export const createProjectAttachApi = (payload) =>
  POST('/api/v2/admin/project/attachment/add', payload);

//attachment Delete

export const DeleteBriefAttachApi = (briefID, fileID) =>
  DeleteApi(
    `/api/v2/admin/brief/attachment/remove?briefId=${briefID}&attachId=${fileID}`
  );
export const DeleteProjectAttachApi = (projectID, fileID) =>
  DeleteApi(
    `/api/v2/admin/project/attachment/remove?projectId=${projectID}&attachId=${fileID}`
  );

export const getDashboardCountApi = () => GET('/api/v1/admin/dashboard/counts');
//other skills json...
export const getOtherSkillsApi = () => GET('/json/skills/skills.json');
export const getTransactionHistoryApi = (id) => {
  return GET(`/api/v1/doctor/BankAccount/TransactionHistory?doctorId=${id}`);
};

export const weavyTokenApi = (payload) => {
  return POST('/api/v2/common/weavy/getToken', payload);
};

export const isValidWebsiteLink = (search) => {
  return GET(`/api/v1/helper/checkUrl?url=${search}`);
};

export const declinePrivateConsultation = (payload) => {
  const { docId, conId } = payload;
  return PATCH(
    `/api/v1/dashboard/doctor/DeclinePrivateRequest?doctorId=${docId}&consultationId=${conId}`
    // '/api/v1/dashboard/doctor/DeclinePrivateRequest?doctorId=66fb993a0505e27480dcef3b&consultationId=671f6dee2c03cac785904dc44'
  );
};

// odds apis start

export const createUser = (payload) => {
  return POST('/api/v1/doctor/user/create', payload);
};
export const userAuth = (payload) => {
  return POST('/api/v1/doctor/user/login', payload);
};
export const forgotPassword = (payload) => {
  return POST('/api/v1/doctor/user/forgotPassword', payload);
};
export const otpVerify = (payload) => {
  return POST('/api/v1/doctor/user/verifyOtp', payload);
};
export const changePassword = (payload) => {
  return POST('/api/v1/doctor/user/changePassword', payload);
};
export const profileCreate = (payload) => {
  return POST('/api/v1/doctor/profile/create', payload);
};
export const getOddsWeavyTokenApi = (payload) => {
  return POST('/api/v1/weavy', payload);
};
export const getNotificationAPI = (id, filter) => {
  return GET(`/api/v1/dashboard/doctor/notification?id=${id}&filter=${filter}`);
};
export const getDashboardApi = (id) =>
  GET(`/api/v1/dashboard/doctor/Dashboard?doctorId=${id}`);
export const getRecentRequest = (id, location, lat, long, doctorId) => {
  return GET(
    `/api/v1/dashboard/doctor/consultation?specalaization=${id}&location=${location}&lat=${lat}&long=${long}&doctorId=${doctorId}`
  );
};
export const getCalenderConsultation = (id) => {
  return GET(
    `/api/v1/dashboard/doctor/AcceptedconsultationbtuserId?userId=${id}`
  );
};
export const getSingleRequest = (id) => {
  return GET(`/api/v1/dashboard/doctor/consultationById?_id=${id}`);
};
export const getProfileById = (id) => {
  return GET(`/api/v1/doctor/profile/getprofile?userId=${id}`);
};
export const getwalletbalancegraph = (id) => {
  return GET(
    `/api/v1/doctor/BankAccount/getEarningHistoryGraph?doctorId=${id}`
  );
};
export const getWidthdrawaHistory = (id) => {
  return GET(`/api/v1/doctor/BankAccount/getPayoutHistory?doctorId=${id}`);
};
export const NotifyMeAPi = (payload) =>
  PATCH('/api/v1/doctor/profile/updatenotifyme', payload);
export const ConsultationAcceptApi = (payload) =>
  PATCH('/api/v1/hospital/consultation/doctorStatusUpdate', payload);

export const UpdateUserDetails = (payload) =>
  PATCH('/api/v1/doctor/profile/update', payload);
export const confirmPassword = (payload) =>
  POST('/api/v1/doctor/user/confirmPassword', payload);
export const DeleteAccountAPI = (payload) =>
  PATCH('/api/v1/doctor/user/DeleteAccount', payload);
export const updateNotificationReadApi = (payload) =>
  PATCH('/api/v1/notification/update', payload);
export const ConsultationbyID = (id) =>
  GET(`/api/v1/dashboard/doctor/consultationById?_id=${id}`);
export const fileUploadApi = (payload, onUploadProgress, file) => {
  return fileUploadwithprogress(
    '/api/v1/uploads/licenceAttch',
    payload,
    onUploadProgress,
    file
  );
};
export const getDoctorBankAccount = (id) =>
  GET(`/api/v1/doctor/BankAccount/getbyuserId?userId=${id}`);
export const SendRequest = (payload) =>
  POST('/api/v1/doctor/BankAccount/Withdraw', payload);

export const CreateDoctorBankAccount = (payload) =>
  POST('/api/v1/doctor/BankAccount/create', payload);

export const updateDoctorBankAccount = (payload) =>
  PATCH('/api/v1/doctor/BankAccount/update', payload);
export const DeleteDoctorBankAccount = (id) =>
  DELETE(`/api/v1/doctor/BankAccount/DeleteAccount?Id=${id}`);

export const getAppointmentHistory = (id) =>
  GET(`/api/v1/doctor/Consultation/AppointmentHistory?doctorId=${id}`);

export const getSpecialization = (token) =>
  GETSpecialization('/api/v1/admin/Specalaization/get', token);
export const updateConsultation = (payload) =>
  PATCH('/api/v1/hospital/consultation/update', payload);

export const getAllConsultation = (
  id,
  sort,
  filterType,
  filterValue,
  location,
  lat,
  long
) => {
  return GET(
    `/api/v1/doctor/consultation/getAllConsultation?doctorId=${id}&status=${sort}&${filterType}=${filterValue}&location=${location}&lat=${lat}&long=${long}`
  );
};
export const getRecentMonthlyConsultations = (payload) => {
  const { doctorId, month, year } = payload;
  return GET(
    `/api/v1/doctor/BankAccount/getMonthlyEarnings?doctorId=${doctorId}&Month=${month}&year=${year}`
  );
};
export const getWithdrawHistory = (id) => {
  return GET(`/api/v1/doctor/BankAccount/WithdrawHistory?doctorId=${id}`);
};
export const getMonthlyEarnings = (payload) => {
  const { doctorId, month, year } = payload;
  return GET(
    `/api/v1/doctor/BankAccount/getMonthlyEarnings?doctorId=${doctorId}&Month=${month}&year=${year}`
  );
};
export const raiseDispute = (payload) =>
  POST('/api/v1/doctor/Consultation/raiseDispute', payload);
