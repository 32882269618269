import React, { useState, useContext } from "react";
import ProfileTopNavigation from "./ProfileTopNavigation";

function AboutUs() {
  const hospitalFeatureList = [
    {
      title: "Swift Expert Consultations:",
      description:
        "Quick access to specialized expertise whenever needed. No delays! No specialist manhunt!",
    },
    {
      title: "Simplified Processes:",
      description: "Streamlined monitoring, billing, and payment processes.",
    },
    {
      title: "Enhanced Patient Care:",
      description:
        "Improved patient outcomes through timely and accurate specialist opinions.",
    },
  ];
  const specialistsFeatureList = [
    {
      title: "Flexible Work Opportunities:",
      description:
        "Opportunity to take on additional consultations on a flexible basis.",
    },
    {
      title: "Simplified Payment Processes:",
      description: "Hassle-free payment and billing.",
    },
    {
      title: "Increased Visibility:",
      description:
        "Enhanced professional visibility and networking opportunities.",
    },
    {
      title: "Reduced Travel and Time:",
      description:
        "Virtual consultations can eliminate the need for physical visits, saving time and effort.",
    },
  ];

  const patientsFeatureList = [
    {
      title: "Faster Access to Specialized Care:",
      description:
        "Patients are quickly connected with the right specialist, reducing wait times for consultations.",
    },
    {
      title: "Improved Diagnostic Accuracy:",
      description:
        "Access to expert opinions can lead to more accurate diagnoses and treatment plans.",
    },
    {
      title: "Enhanced Patient Experience:",
      description:
        "A streamlined process for scheduling appointments and receiving consultations.",
    },
    {
      title: "Reduced Travel and Time:",
      description:
        "Virtual consultations can eliminate the need for physical visits, saving time and effort.",
    },
    {
      title: "Consistent Quality Care:",
      description:
        "Access to a network of verified and experienced specialists.",
    },
  ];

  function HeadingText({ text }) {
    return (
      <div className="mt-4 mb-2 baseText !font-medium max-w-xs">{text}</div>
    );
  }

  function Paragraph({ text }) {
    return <p className="baseText text-navLink">{text}</p>;
  }

  function FeatureList({ features }) {
    return (
      <div className="flex flex-col gap-1">
        {features.map((feature, index) => (
          <div key={index}>
            <span className="baseText !font-medium max-w-xs me-1">
              {feature.title}
            </span>
            <span className="baseText text-navLink">{feature.description}</span>
          </div>
        ))}
      </div>
    );
  }
  return (
    <div className="text-coalBlack">
      <ProfileTopNavigation pageTitle={"About Us"} />
      <section className="my-8 mx-2">
        <HeadingText text={"MODO: Medical On Demand Opinions"} />
        <Paragraph
          text={`MODO is a specialized platform designed to streamline the unorganized
          specialist healthcare system in India, thereby revolutionizing
          specialist access in healthcare. By connecting hospitals with
          certified, verified specialists on an as-needed basis, MODO ensures
          seamless access to expert opinions.`}
        />
        <HeadingText text={"Key benefits of MODO:"} />
        <Paragraph
          text={`All specialists onboard MODO are vetted and their qualifications are
          verified.`}
        />
        <HeadingText text={"For Hospitals:"} />
        <FeatureList features={hospitalFeatureList} />
        <HeadingText text={"For Specialists:"} />
        <FeatureList features={specialistsFeatureList} />
        <HeadingText text={"For Patients:"} />
        <FeatureList features={patientsFeatureList} />
        <div className="mt-4 pb-24 lg:pb-0">
          <Paragraph
            text={`MODO is committed to delivering reliable, efficient, and user-friendly
            healthcare solutions for both hospitals and specialists improving
            patient care and satisfaction`}
          />
        </div>
      </section>
    </div>
  );
}

export default AboutUs;
