import * as yup from 'yup';
import { isValidWebsiteLink } from '../../apis';
import { isValidPhoneNumber } from 'react-phone-number-input';

const URL =
  /^(?:(?:https?|http|www)\:\/\/)?(?:[a-zA-Z0-9\-]+\.)+(?:(?:[a-zA-Z]{2,4})|(?:[a-zA-Z0-9\-]+))(?:\:[0-9]+)?(?:[a-zA-Z0-9\-\._\?\,\'\/\\\+&%\$#\=~])*$/;
export const HelpcenterValidation = yup.object({
  name: yup.string().required('This field can\'t be empty'),
  registeredemail: yup
    .string()
    .email('Invalid email')
    .required('Please enter a valid email'),
  registeredmobile: yup
    .string()
    .matches(/^\d{10}$/, 'Mobile number must be exactly 10 digits')
    .required('Mobile number is required'),
    description:yup.string().required('This field can\'t be empty'),
    subject:yup.string().required('This field can\'t be empty'),
});
export const WltSignInValidation = yup.object({
  email: yup
    .string()
    .email('please enter a valid email')
    .required('This field can\'t be Empty'),
});
export const SignupValidation = yup.object({
  //userName: yup.string().required('This field can\'t be Empty'),
  email: yup
    .string()
    .email('Invalid email')
    .required('Please enter a valid email'),
  password: yup
    .string()
    .min(8, 'Password with a minimum of 8 characters is required!')
    .max(50, 'Too Long!')
    .required('This field can\'t be Empty'),
  isTermsAccepted: yup
    .bool()
    .oneOf([true], 'You must accept the terms and conditions.'),
});
export const SignInValidation = yup.object({
  email: yup
    .string()
    .email('Please enter a valid email')
    .required('This field can\'t be Empty'),
  password: yup
    .string()
    .required('Please enter a valid password'),
  keepMeLoggedIn: yup.bool()
    
});
export const ForgotPasswordEmailValidation = yup.object({
  email: yup
    .string()
    .email('Please enter a valid email')
    .required('This field can\'t be Empty'),
});
export const ResetPasswordValidation = yup.object({
  newpassword: yup
    .string()
    .min(8, 'Password with a minimum of 8 characters is required!')
    .max(50, 'Too Long!')
    .required('This field can\'t be Empty'),
  confirmpassword: yup
    .string()
    .oneOf([yup.ref('newpassword'), null], 'Passwords must match')
    .min(8, 'Password with a minimum of 8 characters is required!')
    .max(50, 'Too Long!')
    .required('This field can\'t be Empty'),
});
export const DoctorRegistrationStepOne = yup.object({
  docName: yup.string().required('This field can\'t be Empty'),
  birthDate: yup
    .date()
    .required('This field can\'t be Empty')
    .min(new Date(1900, 0, 1), 'Age must be after Jan 1st, 1900')
    .max(new Date(), 'Age cannot be in the future'),
  gender: yup
    .string()
    .required('Selecting a gender is required')
    .oneOf(['male', 'female', 'others'], 'Invalid gender selection'),
  phoneNo: yup
    .string()
    .required('This field can\'t be Empty')
    .matches(/^(?!0)\d{10}$/, 'Invalid mobile number'),
  countryCode: yup.string(),
});
export const DoctorRegistrationStepTwo = yup.object({
  specialization: yup.string().required('This field can\'t be Empty'),
  // chosenOption: yup.string(),
  regNumber: yup.string().required('This field can\'t be Empty'),
  smCouncil: yup.string().required('This field can\'t be Empty'),
  dateOfReg: yup
    .date()
    .required('This field can\'t be Empty')
    .min(new Date(1900, 0, 1), 'Registration must be after Jan 1st, 1900')
    .max(new Date(), 'Registration cannot be in the future'),
});
export const DoctorRegistrationStepThree = yup.object({
  practiceHospital: yup.string(),
  HospitalNumber: yup.string(),
  HospitalMobileNumber: yup.string().matches(/^(?!0)\d{10}$/, 'Invalid mobile number'),
});
export const DoctorRegistrationStepFour = yup.object({
  MedicalLicense: yup.mixed().required('This field can\'t be Empty'),
  DegreeCertificate: yup.mixed().required('This field can\'t be Empty'),
  refPhoneNo: yup
    .string()
    .required('This field can\'t be Empty')
    .matches(/^(?!0)\d{10}$/, 'Invalid mobile number'),
  refContact: yup.string().required('This field can\'t be Empty'),
});
export const updateUserValidation = yup.object({
  name: yup.string().required('This field can\'t be Empty'),
  email: yup
    .string()
    .email('Please enter a valid email')
    .required('This field can\'t be Empty'),
  image: yup.mixed(),
});
export const editUserProfile = yup.object({
  userEmail: yup.string().email('Invalid email').required('Please enter a valid email'),
  contactNumber: yup.string()
    .matches(/^\d{10}$/, 'Mobile number must be exactly 10 digits')
    .required('Mobile number is required'),
  // dateOfBirth: yup.string().required('This field can\'t be empty'),
  // location: yup.string().required('This field can\'t be empty'),
});
export const deleteAcctPassword = yup.object({
  password: yup
    .string()
    .min(5, 'Password with a minimum of five characters is required!')
    .max(50, 'Too Long!')
    .required('This field can\'t be empty'),
});
// edit user profile validation end
export const GetBankAccountDetails = yup.object({
  holderName: yup.string(),
  accountNumber: yup.string(),
  ifscCode: yup.string(),
 
});

export const AccountDetailsValidation = yup.object({
  experience: yup
    .string()
    .matches(
      /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
      'Not valid, enter numbers only'
    ),
  workedForYourself: yup
    .string()
    .matches(
      /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
      'Not valid, enter numbers only'
    ),
  minDayRate: yup
    .string()
    .matches(
      /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
      'Not valid, enter numbers only'
    ),
  isTerms: yup
    .boolean()
    .oneOf([true], 'You cannot proceed without agreeing the terms'),
});



