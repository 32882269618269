import React, { useContext, useEffect, useState } from 'react';
import DashboardSidenav from '../Sidebar/Sidebar';
import SearchHeader from './SearchHeader';
import { AppContext } from '../../appContext';
import WeavyToastNotification from '../oddsChat/component/WeavyToastNotification';
const AdminLayout = ({ children }) => {
  const { userDetails } = useContext(AppContext);
  const urlPathLocation = window.location.pathname;
  return (
    <div className="flex ">
      <DashboardSidenav />
      <div className="w-full min-h-screen bg-[#FAFAFE] xl:bg-bodybackground">
        <SearchHeader />
        <div className={'content '}>{children}</div>
      </div>
      <WeavyToastNotification/>
      
    </div>
  );
};
export default AdminLayout;
