import React from 'react';
import AdminLayout from '../../components/admin-layout';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import EventCalendar from '../../components/Calendar/EventCalendar';
import { BsPlusLg } from 'react-icons/bs';
function CalendarIndex() {
  return (
    <AdminLayout>
      <div className="p-4">
        <div className="flex justify-between items-center rounded-lg bg-white p-4 xl:p-5 ">
          <p className=" text-coalBlack headingText capitalize">
            Calendar{' '}
          </p>
        </div>
        <div className="mt-4 xl:mt-12">
          <EventCalendar />
        </div>
      </div>
    </AdminLayout>
  );
}

export default CalendarIndex;
